<template>
    <div class="calendar">
        <div class="select">
            <el-form inline>
                <el-form-item style="width: 96px">
                    <el-select
                        v-model="curYear"
                        placeholder="请选择"
                        :popper-append-to-body="false"
                    >
                        <el-option
                            v-for="item in yearOptions"
                            :key="item.key"
                            :value="item.value"
                            :label="item.label"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item style="width: 96px">
                    <el-select
                        v-model="curMonth"
                        placeholder="请选择"
                        :popper-append-to-body="false"
                    >
                        <el-option
                            v-for="item in monthOptions"
                            :key="item.key"
                            :value="item.value"
                            :label="item.label"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <div class="btn_class">
                        <div
                            class="up_down_month_btn"
                            @click="handleQuickChange('prev')"
                        >
                            <img
                                class="up_down_icon"
                                :src="upTopUrl"
                                alt=""
                            />上一月
                        </div>
                        <div
                            class="up_down_month_btn"
                            @click="handleQuickChange('next')"
                        >
                            <img
                                class="up_down_icon"
                                :src="downBottomUrl"
                                alt=""
                            />下一月
                        </div>
                    </div>
                </el-form-item>
            </el-form>
        </div>

        <table
            class="calendar-table"
            :style="{width}"
        >
            <thead>
                <tr style="text-align: center">
                    <th
                        v-for="(item, i) in weeks"
                        :key="i"
                    >
                        {{ item }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(dates, i) in res"
                    :key="i"
                    :style="{height: tbodyHeight}"
                >
                    <td
                        v-for="(item, index) in dates"
                        :key="index"
                        :class="{
                            notCurMonth: !item.isCurMonth,
                            currentDay: item.date === curDate,
                            selectDay: item.isSelected,
                            rangeSelectd: item.isRangeSelected,
                            weekend: item.isWeekend,
                            isShowExpanded: item.isExpanded,
                        }"
                        @click.prevent="handleItemClick(item, i, index)"
                        @mouseover="handleItemMove(item, i, index)"
                    >
                        <div :class="{showEslip: item.isSelected}">
                            <div>
                                <slot
                                    name="repeat"
                                    :repeatData="item"
                                ></slot>
                            </div>
                            <div
                                style="text-align: center; margin-bottom: 10px"
                            >
                                {{
                                    parseInt(
                                        item.date.split("-").slice(2).join("-"),
                                    )
                                }}
                            </div>
                            <div
                                class="show_all_time"
                                :class="[item.isExpanded ? 'expanded' : '']"
                                @click.stop="handleShowAllTime(item)"
                                v-if="
                                    item.timeRangeArr &&
                                    item.timeRangeArr.length > 1
                                "
                            >
                                <i class="el-icon-arrow-down" />
                            </div>
                            <slot
                                :data="item"
                                :dataIndex="index"
                            />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {
    getDaysInMonth,
    handleCrateDate,
    handleCreateDatePicker,
    parseTime,
} from "./utils";

export default {
    components: {},
    props: {
        selectMode: {
            type: String,
            default: "click",
        },
        startOfWeek: {
            type: Number,
            default: 1,
        },
        canSelect: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: "70%",
        },
        tbodyHeight: {
            type: String,
            default: "60px",
        },
        dateList: {
            type: Array,
        },
        timeRangeArr: {
            type: Array,
        },
    },
    data() {
        return {
            monthOptions: [],
            yearOptions: [],
            weeks: ["一", "二", "三", "四", "五", "六", "日"],
            curYear: new Date().getFullYear(), // 当前年
            curMonth: new Date().getMonth(), // 当前月
            days: 0, // 当前月总共天数
            curDate: parseTime(new Date().getTime()), // 当前日期 yyyy-MM-dd 格式，用来匹配是否是当前日期
            prevDays: [], // 非当前月的上一月展示的日期
            rearDays: [], // 非当前月的下一月展示的日期
            curDays: [], // 当前月的日期
            showDays: [], // 总共展示的42个日期
            res: [], // 二维数组
            selectedDates: [], // 选中的日期
            selectedMode: false, // true表示点击， false表示滑动
            moveIndex: [], // 两个，第一个是起始，第二个是结束
            canMove: false, // 当moveIndex数组有一个值时，可以触发滑动
        };
    },
    computed: {
        upTopUrl() {
            return require("@/assets/images/up_top.png");
        },
        downBottomUrl() {
            return require("@/assets/images/down_bottom.png");
        },
    },
    watch: {
        curMonth: {
            handler(val) {
                this.handleGetDays(this.curYear, val, this.startOfWeek);
            },
        },
        curYear: {
            handler(val) {
                this.handleGetDays(val, this.curMonth, this.startOfWeek);
            },
        },
    },
    created() {
        this.weeks.unshift(...this.weeks.splice(this.startOfWeek - 1));
        this.handleGetDays(this.curYear, this.curMonth, this.startOfWeek);
        this.selectedMode = this.selectMode === "click";
    },
    mounted() {
        this.monthOptions = handleCreateDatePicker().months;
        this.yearOptions = handleCreateDatePicker().years;
        if (localStorage.selectedDates)
            this.selectedDates = JSON.parse(localStorage.selectedDates);
    },
    methods: {
        handleGetDays(year, month, startOfWeek) {
            this.showDays = [];
            this.days = getDaysInMonth(year, month);
            let firstDayOfWeek = new Date(`${year}-${month + 1}-01`).getDay();

            // 处理周起始日
            const obj = {
                1: "一",
                2: "二",
                3: "三",
                4: "四",
                5: "五",
                6: "六",
                0: "日",
            };
            const firstDayInCN = obj[firstDayOfWeek];
            const index = this.weeks.indexOf(firstDayInCN);

            if (firstDayOfWeek === 0) {
                // 星期天为0 星期一为1 ，以此类推
                firstDayOfWeek = 7;
            }

            this.prevDays = handleCrateDate(year, month, 1, index + 1, "prev");
            this.rearDays = handleCrateDate(
                year,
                month,
                1,
                42 - this.days - index,
                "rear",
            );

            this.curDays = handleCrateDate(year, month, 1, this.days);
            this.showDays.unshift(...this.prevDays);
            this.showDays.push(...this.curDays);
            this.showDays.push(...this.rearDays);
            this.res = this.handleFormatDates(this.showDays);
            this.res.forEach((item) => {
                item.forEach((it) => {
                    if (this.dateList && this.dateList.length > 0) {
                        let arr = this.dateList.filter(
                            (i) => i.date == it.date,
                        );
                        if (arr && arr.length > 0) {
                            it.isSelected = true;
                            it.timeRangeArr = arr[0].timeArr;
                        } else {
                            it.timeRangeArr = null;
                        }
                    }
                });
            });
        },
        handleFormatDates(arr, size = 7) {
            // 传入长度42的原数组，最终转换成二维数组
            const arr2 = [];
            for (let i = 0; i < size; i++) {
                const temp = arr.slice(i * size, i * size + size);
                arr2.push(temp);
            }
            return arr2;
        },
        handleTableHead(start) {
            const sliceDates = this.weeks.splice(start - 1);
            this.weeks.unshift(...sliceDates);
        },
        handleItemClick(item, i, j) {
            if (!this.canSelect) return;
            if (this.selectedMode) {
                this.$nextTick(() => {
                    this.res[i][j].isSelected = !this.res[i][j].isSelected;
                    if (this.res[i][j].isSelected) {
                        this.selectedDates.push(this.res[i][j].date);
                        this.selectedDates = Array.from(
                            new Set(this.selectedDates),
                        );
                        let dateObj = {
                            timeArr: [],
                        };
                        console.log("传过来的this.pushArr", this.timeRangeArr);
                        if (this.timeRangeArr && this.timeRangeArr.length > 0) {
                            this.timeRangeArr.forEach((item1, index1) => {
                                if (item1.isShowTip) {
                                    item1.time = "";
                                }
                                if (!item1.time) {
                                    item1.time = "";
                                }
                                let obj = {
                                    time: item1.time,
                                    isRepeatDate: false,
                                };
                                dateObj.timeArr.push(obj);
                            });
                            item.timeRangeArr = dateObj.timeArr;
                        }
                    } else {
                        this.selectedDates.splice(
                            this.selectedDates.indexOf(item.date),
                            1,
                        );
                        item.timeRangeArr = null;
                    }
                    this.$emit("dateSelected", this.selectedDates, item.date);
                });
            } else {
                // 滑动模式下，第一次点击是起始，第二次点击是结束
                const index = i * 7 + j;
                this.canMove = true;
                if (this.moveIndex.length === 1) {
                    this.canMove = false;
                }
                if (this.moveIndex.length === 2) {
                    this.showDays.forEach((item) => {
                        item.isSelected = false;
                        item.isRangeSelected = false;
                    });
                    this.canMove = true;
                    this.moveIndex.length = 0;
                }
                this.moveIndex.push(index);
                this.moveIndex.sort((a, b) => a - b);
                this.selectedDates = this.showDays.slice(
                    this.moveIndex[0],
                    this.moveIndex[1] + 1,
                );
                this.selectedDates.length !== 0 &&
                    this.$emit("dateSelected", this.selectedDates);
            }
        },
        handleItemMove(data, i, j) {
            if (this.canMove && !this.selectedMode) {
                const index = i * 7 + j;
                this.showDays.forEach((item) => {
                    item.isSelected = false;
                    item.isRangeSelected = false;
                });
                // 让第一个日期和最后一个日期显示蓝色高亮
                this.showDays[index].isSelected = true;
                this.showDays[this.moveIndex[0]].isSelected = true;

                // 不同情况的判断，当用户的鼠标滑动进日期的索引小于起始日期的索引，要做if else处理
                if (this.moveIndex[0] < index) {
                    for (let i = this.moveIndex[0] + 1; i < index; i++) {
                        this.showDays[i].isRangeSelected = true;
                    }
                } else {
                    for (let i = index + 1; i < this.moveIndex[0]; i++) {
                        this.showDays[i].isRangeSelected = true;
                    }
                }
            }
        },
        handleQuickChange(type) {
            if (type === "prev") {
                this.curMonth--;
                if (this.curMonth === -1) {
                    this.curMonth = 11;
                    this.curYear -= 1;
                }
            } else if (type === "next") {
                this.curMonth++;
                if (this.curMonth === 12) {
                    this.curMonth = 0;
                    this.curYear += 1;
                }
            }
        },
        handleShowAllTime(item) {
            this.$set(item, "isExpanded", !item["isExpanded"]);
        },
    },
};
</script>

<style scoped lang="scss">
.calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.select {
    width: 100%;
    .el-input--small .el-input__inner {
        line-height: 30px;
    }
    .el-input--small {
        font-style: 14px;
    }
}
.calendar-table {
    table-layout: fixed;
    transition: 0.3s;

    thead tr {
        height: 50px;
    }

    tbody tr {
        td {
            cursor: pointer;
            border: 1px solid #f0f0f0;
            padding: 10px;
        }
    }
}
.show_all_time {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    color: #2b2e33;
    line-height: 10px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    .el-icon-arrow-down {
        width: 20px;
        height: 20px;
        line-height: 22px;
        text-align: center;
        font-size: 13px;
        border-radius: 50%;
        color: #bfbfbf;
        background-color: #ffffff;
        transition: rotate 0.2s ease-in;
    }
    &:hover {
        .el-icon-arrow-down {
            background-color: #3c7fff;
            color: #fff;
        }
    }

    &.expanded {
        .el-icon-arrow-down {
            transform: rotate(-180deg);
            background-color: #3c7fff;
            color: #fff;
        }
    }
}

.notCurMonth {
    color: #c0c4cc;
}

.currentDay {
    color: #3c7fff;
}

.selectDay {
    color: #595959;
    background-color: #e5f0ff;
}
.showEslip {
    position: relative;
}
.isShowExpanded {
    border: 1px dashed #3c7fff !important;
    border-bottom: none;
}

.rangeSelectd {
    color: #606266;
    background-color: #dee2e9;
}

.weekend {
    color: #f73131;
}
.btn_class {
    display: flex;
    align-items: center;
}
.up_down_month_btn {
    width: 72px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dcdee0;
    margin-right: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #2b2e33;
}
.up_down_icon {
    width: 8px;
    height: 12px;
    margin-right: 4px;
    margin-left: 8px;
}
</style>
